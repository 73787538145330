.form-group label {
    text-transform: uppercase;
    font-size: 0.8em;
    color: #888;
}

.section-separator {
    padding: 8px;
}

div.form-group {
    margin: 0;
}

div.form-group > div.checkbox {
    margin: 0;
}

[clac-set-name-floid] {
    background: #CFC !important;
}

div.kar-checkbox {
    display: flex;
}